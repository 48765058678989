import { createAsyncThunk } from '@reduxjs/toolkit'
import { ACTIVE_DEFAULT_COLOR, REGULAR_DEFAULT_COLOR } from '../../../../consts'
import { ApplicationMode } from '../../../../types'
import { connectionApi, shapeApi } from '../../../api/api-wrapper'
import type { ConnectionDto, DiagramComponent, ShapeTagDto } from '../../../generated/backend'
import { DiagramComponentType, ShapeType } from '../../../generated/backend'
import type { AppShape } from '../../slice/drawSlice'
import type { IColors } from '../../slice/types'
import type { RootState } from '../../store'
import type {
	ComponentTypeFromApp,
	DiagramComponentMapping,
	GetDiagramComponentsAndConnections,
	GetDiagramComponentsTypes,
	GetShapesOnDraw,
	SortDiagramComponent,
} from './types'

const getShapesOnDraw: GetShapesOnDraw = async imageId => {
	const res = await shapeApi.getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageId)
	return res.data
}

const getDiagramComponentsAndConnections: GetDiagramComponentsAndConnections = async () => {
	const res = await shapeApi.getAllShapesApiShapeGet()

	return {
		connections: res.data.connections,
		diagramComponents: res.data.diagramComponent,
	}
}

const getDiagramComponentsTypes: GetDiagramComponentsTypes = mode => {
	if (mode === ApplicationMode.RBI_APP) {
		return [
			DiagramComponentType.RbiComponent,
			DiagramComponentType.RbiCorrosionLoop,
			DiagramComponentType.RbiEquipment,
		] as const
	}

	if (mode === ApplicationMode.HAZOP_APP) {
		return [DiagramComponentType.HazopNode, DiagramComponentType.HazopEquipment] as const
	}

	if (mode === ApplicationMode.DEMO_APP) {
		return [DiagramComponentType.Symbol] as const
	}

	return null
}

const createDiagramComponentMapping = (mode: ApplicationMode): DiagramComponentMapping<typeof mode> | null => {
	const listOfKeys = getDiagramComponentsTypes(mode)

	if (listOfKeys?.length && mode) {
		return listOfKeys.reduce(
			(acc, key) => {
				return {
					...acc,
					[key]: [],
				}
			},
			{} as unknown as DiagramComponentMapping<typeof mode>
		)
	}

	return null
}

const sortDiagramComponent: SortDiagramComponent = (app, componentsMapping, component) => {
	try {
		if (app === ApplicationMode.HAZOP_APP) {
			const type = component.diagram_component_type as ComponentTypeFromApp<ApplicationMode.HAZOP_APP>
			;(componentsMapping as DiagramComponentMapping<ApplicationMode.HAZOP_APP>)[type].push(component)
		}

		if (app === ApplicationMode.RBI_APP) {
			const type = component.diagram_component_type as ComponentTypeFromApp<ApplicationMode.RBI_APP>
			;(componentsMapping as DiagramComponentMapping<ApplicationMode.RBI_APP>)[type].push(component)
		}

		if (app === ApplicationMode.DEMO_APP) {
			const type = component.diagram_component_type as ComponentTypeFromApp<ApplicationMode.DEMO_APP>
			;(componentsMapping as DiagramComponentMapping<ApplicationMode.DEMO_APP>)[type].push(component)
		}
	} catch (e) {
		throw new Error(`Выбран неправильный режим приложения. Выбранный режим: ${app}`)
	}
}

const checkAndUpdateComponentColors = (component: DiagramComponent): void => {
	if (component.color) {
		if (component.color.length > 7) {
			component.color = component.color.substring(0, 6)
		}

		const colorIsIncorrect = !component.color.startsWith('#')
		const colorIsWhite = component.color === 'FFFFFF' || component.color === '#ffffff'

		if (colorIsIncorrect) {
			component.color = `#${component.color}`
		}

		if (colorIsWhite) {
			component.color = '#215294'
		}
	}
}

const getColorsForShape = (component: DiagramComponent): IColors => {
	const componentWithColor: string[] = [
		DiagramComponentType.HazopEquipment,
		DiagramComponentType.Symbol,
		DiagramComponentType.RbiCorrosionLoop,
	]

	const colors: IColors = {
		active: ACTIVE_DEFAULT_COLOR,
		regular: REGULAR_DEFAULT_COLOR,
	}

	if (component.color && componentWithColor.includes(component.diagram_component_type)) {
		colors.active = component.color
		colors.regular = `${component.color}50`
	}

	return colors
}

export const getAppData = createAsyncThunk('drawApp/getAppData', async (_, { getState, rejectWithValue }) => {
	try {
		const { applicationMode, image } = (getState() as RootState).draw

		if (!image.id) {
			return rejectWithValue({ error: { message: 'Не выбрано изображение' } })
		}

		const diagramComponentsMapping = createDiagramComponentMapping(applicationMode)

		if (!diagramComponentsMapping) {
			return rejectWithValue({ error: { message: 'Неизвестный тип приложения' } })
		}

		const shapesOnDraw = await getShapesOnDraw(image.id)
		const { connections, diagramComponents } = await getDiagramComponentsAndConnections()

		for (const component of diagramComponents) {
			if (component.diagram_component_type) {
				sortDiagramComponent(applicationMode, diagramComponentsMapping, component)
			}
		}

		const lines: AppShape[] = []
		const rectangles: AppShape[] = []
		const tagsOnDraw: ShapeTagDto[] = []
		const connectors = new Set<ConnectionDto>()
		const diagramComponentsOnDrawSet = new Set<DiagramComponent>()

		for (const component of diagramComponents) {
			const shapes = shapesOnDraw.filter(shape => {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				return component.shape_ids.includes(shape.id!)
			}) as AppShape[]

			shapes.sort((a, b) => {
				if (a.x < b.x || a.y < b.y) {
					return -1
				} else if (a.x >= b.x || a.y >= b.y) {
					return 1
				}
				return 0
			})

			if (shapes.length > 0) {
				const horizontalLineInList = shapes.filter(shape => {
					return shape.shapeType === ShapeType.Line && shape.width > 10
				})
				if (horizontalLineInList.length > 0 && horizontalLineInList[0].entitiesForLabels) {
					horizontalLineInList[0].entitiesForLabels.push(component)
				} else if (horizontalLineInList.length > 0) {
					horizontalLineInList[0].entitiesForLabels = [component]
				} else if (horizontalLineInList.length === 0) {
					if (shapes[0].entitiesForLabels && shapes[0].entitiesForLabels.length > 0) {
						shapes[0].entitiesForLabels.push(component)
					} else {
						shapes[0].entitiesForLabels = [component]
					}
				}
			}
		}

		shapesOnDraw.forEach(item => {
			const shape: AppShape = {
				...item,
				entities: createDiagramComponentMapping(applicationMode),
				shapeType: item.shape_type,
				connections: [],
				colors: {
					active: ACTIVE_DEFAULT_COLOR,
					regular: REGULAR_DEFAULT_COLOR,
				},
			}

			for (const component of diagramComponents) {
				const componentHasRelatedWithShape = component.shape_ids.includes(shape.id)

				if (componentHasRelatedWithShape && shape.entities) {
					sortDiagramComponent(applicationMode, shape.entities, component)
					diagramComponentsOnDrawSet.add(component)
					checkAndUpdateComponentColors(component)
					shape.colors = getColorsForShape(component)
				}
			}

			for (const connection of connections) {
				const connectionHasRelatedWithShape = connection.shapes_id?.includes(shape.id)

				if (connectionHasRelatedWithShape) {
					shape.connections.push(connection)
					connectors.add(connection)
				}

				if (connection.shapes_id?.length === 0) {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					void connectionApi.deleteConnectionApiConnectionConnectionIdDelete(connection.id!)
				}
			}

			if (shape.tags.length > 0) {
				shape.tags?.forEach(tag => {
					tagsOnDraw.push(tag)
				})
			}

			if (shape.shapeType === ShapeType.Line) {
				lines.push(shape)
			}

			if (shape.shapeType === ShapeType.Rectangle) {
				rectangles.push(shape)
			}
		})

		const diagramComponentsOnDraw = createDiagramComponentMapping(applicationMode)

		if (diagramComponentsOnDrawSet.size > 0 && diagramComponentsOnDraw) {
			Array.from(diagramComponentsOnDrawSet).forEach(component => {
				sortDiagramComponent(applicationMode, diagramComponentsOnDraw, component)
			})
		}

		return {
			lines,
			rectangles,
			diagramComponents: diagramComponentsMapping,
			diagramComponentsOnDraw,
			tags: tagsOnDraw,
			connectors: Array.from(connectors),
		}
	} catch (e) {
		return rejectWithValue({ error: { message: (e as Error).message } })
	}
})
