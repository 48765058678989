import React, { type FC, useEffect, useRef } from 'react';
import { Stage as KonvaStage } from 'react-konva';
import { DrawingLayer, ImageLayer } from '../layers';
import * as style from './style.module.css';
import { useDraw } from './useDraw/useDraw';
import { setContextMenuPosition, setStageAttrs, useAppDispatch, useAppSelector } from '../../store';
import type Konva from 'konva';
import ContextMenu from '../contextMenu/ContextMenu';
import TagDraft from '../shapes/drafts/TagDraft';

const Stage: FC = () => {
  const stageContainer = useRef<HTMLDivElement>(null);
  const stage = useRef<React.ElementRef<typeof KonvaStage>>(null);
  const { onClick, onMouseUp, onMouseMove, onMouseDown } = useDraw(stage.current);
  const { draft, zoom, position } = useAppSelector((state) => state.draw.stage);
  const { imageLayer } = useAppSelector((state) => state.draw);
  const dispatch = useAppDispatch();

  const onDragEnd = (e: Konva.KonvaEventObject<DragEvent>): void => {
    const stage = e.target.getStage();
    const transformer = stage?.findOne('.transformer');
    if (stage && transformer?.getPosition().x) {
      const position = {
        x: stage.container().offsetLeft + transformer.absolutePosition().x,
        y: stage.container().offsetTop + transformer.absolutePosition().y,
      };
      dispatch(setContextMenuPosition(position));
    }
  };

  useEffect(() => {
    dispatch(setStageAttrs({
      position,
      scale: imageLayer.zoom,
    }));
  }, [imageLayer.zoom]);

  return (
    <div
      className={style.stageWrapper}
      ref={stageContainer}
    >
      <KonvaStage
        ref={stage}
        width={window.innerWidth}
        height={window.innerHeight}
        scale={zoom}
        x={position.x}
        y={position.y}
        className={style.stage}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseMove={(e) => { onMouseMove(e, draft); }}
        onMouseUp={(e) => { onMouseUp(e, draft); }}
        onDragEnd={onDragEnd}
        rotation={0}
      >
        <ImageLayer stageContainer={stageContainer.current} />
        <DrawingLayer />
      </KonvaStage>
      <ContextMenu />
      <TagDraft />
    </div>
  );
};

export default Stage;
