import React, { type FC } from 'react'
import type { DiagramComponent } from '../../generated/backend'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'

interface IProps {
	entities: DiagramComponent[]
	setEditMode: () => void
}

const EntityLabel: FC<IProps> = ({ entities, setEditMode }) => {
	const renderTitles = (): React.JSX.Element[] | undefined => {
		return entities.map(entity => {
			return (
				<div
					key={entity.id}
					style={{
						paddingLeft: '4px',
						paddingRight: '4px',
					}}
				>
					{entity.name}
				</div>
			)
		})
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			{renderTitles()}
			<Button icon={<EditOutlined />} onClick={setEditMode} type='link' />
		</div>
	)
}

export default EntityLabel
