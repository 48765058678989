import React, { type FC } from 'react';
import { Layer } from 'react-konva';
import BaseDraft from '../shapes/drafts/BaseDraft';
import Cleans from '../shapes/cleans';
import SelectionTool from '../shapes/selectionTransformer/SelectionTool';

const DrawingLayer: FC = () => {
  return (
    <Layer>
      <Cleans />
      <BaseDraft />
      <SelectionTool />
    </Layer>
  );
};

export default DrawingLayer;
