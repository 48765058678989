import React, { type FC, useEffect, useState } from 'react';
import { Table, type TableColumnsType } from 'antd';
import { Link } from 'react-router-dom';
import EmptyData from '../../emptyData/EmptyData';
import type { ImageDrawingFileDto } from '../../../lib/src/generated/backend';
import { imageApi, pdfApi } from '../../../lib';

const ImageTable: FC = () => {
  const [PDFList, setPDFList] = useState<ImageDrawingFileDto[]>([]);

  useEffect(() => {
    (async () => {
      const res = await imageApi.getAllImageDrawingFilesApiImageDrawingFileGet();
      setPDFList(res.data);
    })();
  }, []);

  const columns: TableColumnsType<ImageDrawingFileDto> = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        return <Link to={`/drawing/${record.id}`}>{record.filename}</Link>;
      }
    },
    {
      title: 'Страница №',
      dataIndex: 'imageNumber',
      key: 'imageNumber',
    },
    {
      title: 'PDF',
      dataIndex: ['parentPdfDrawingFile', 'filename'],
      key: 'parentPdfDrawingFile.fileName'
    },
  ];

  return (
    <Table
      rowKey={'id'}
      columns={columns}
      dataSource={PDFList}
      locale={{
        emptyText: <EmptyData text="Загрузите PDF-файл" />
      }}
    />
  );
};

export default ImageTable;
