import { createAsyncThunk } from '@reduxjs/toolkit'
import { connectionApi, shapeApi } from '../../../api/api-wrapper'
import type { ConnectionDto, ShapeDtoRequest } from '../../../generated/backend'

export const finishDraw = createAsyncThunk(
	'draw/finishDrawing',
	async ({ draft, connections }: { draft: ShapeDtoRequest; connections: ConnectionDto[] }, thunkAPI) => {
		try {
			if (draft.height && draft.y && draft.height < 0) {
				draft.y = draft.y + draft.height
				draft.height = Math.abs(draft.height)
			}
			if (draft.width && draft.x && draft.width < 0) {
				draft.x = draft.x + draft.width
				draft.width = Math.abs(draft.width)
			}
			if (draft.width && draft.height && (draft.width < 10 || draft.height < 10)) {
				return
			}

			const createdShape = await shapeApi.createShapeApiShapePost(draft)
			const connectionsForCreate: ConnectionDto[] = []
			const connectionsForUpdate: ConnectionDto[] = []

			connections.forEach(connection => {
				if (Object.hasOwn(connection, 'id')) {
					connectionsForUpdate.push(connection)
				} else {
					connectionsForCreate.push(connection)
				}
			})

			const createConnectionsResult = await Promise.all(
				connectionsForCreate.map(connection => {
					return connectionApi.createConnectionApiConnectionPost({
						x: connection.x,
						y: connection.y,
						color: 'blue',
						width: 15,
						height: 15,
					})
				})
			)

			const createdConnections = createConnectionsResult.map((connection, index) => {
				return {
					...connection.data,
					shapesId: connectionsForCreate[index].shapes_id,
				}
			})

			await Promise.all(
				createdConnections.map(connection => {
					if (connection.shapes_id?.[0] && connection.id) {
						void shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(
							connection.shapes_id[0],
							connection.id
						)
						connectionsForUpdate.push(connection)
					}
				})
			)

			await Promise.all(
				connectionsForUpdate.map(connection => {
					if (createdShape.data.id && connection.id) {
						void shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(
							createdShape.data.id,
							connection.id
						)
					}
				})
			)
		} catch (e) {
			return thunkAPI.rejectWithValue(e)
		}
	}
)
