import React, { type FC, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../store'
import { List, Typography } from 'antd'
import ListItem from './ListItem'
import EmptyData from '../../../EmptyData'
import type { DiagramComponent } from '../../../../generated/backend'
import type { DemoDiagramComponentMapping, HazopDiagramComponentMapping } from '../../../../store/actions/common/types'

interface IProps {
	title: string
}

const DEMOEntityList: FC<IProps> = ({ title }) => {
	const diagramComponents = useAppSelector(state => state.draw.stage.diagramComponents)
	const { applicationMode } = useAppSelector(state => state.draw)
	const [dataSource, setDataSource] = useState<DiagramComponent[]>([])

	useEffect(() => {
		if (applicationMode === 'DEMO_APP' && (diagramComponents as DemoDiagramComponentMapping)?.Symbol) {
			setDataSource((diagramComponents as DemoDiagramComponentMapping).Symbol)
		}
		if (applicationMode === 'HAZOP_APP' && (diagramComponents as HazopDiagramComponentMapping)?.HazopEquipment) {
			setDataSource((diagramComponents as HazopDiagramComponentMapping).HazopEquipment)
		}
	}, [applicationMode, diagramComponents])

	return (
		<div>
			<Typography.Title level={5} style={{ padding: '8px' }}>
				{title}
			</Typography.Title>
			<List
				dataSource={dataSource}
				locale={{
					emptyText: <EmptyData />,
				}}
				renderItem={item => <ListItem item={item} />}
			/>
		</div>
	)
}

export default DEMOEntityList
