import React, { type FC, useState } from 'react'
import { ConfigProvider, Input } from 'antd'
import { getAppData, setSelectedTool, setTagDraft, useAppDispatch, useAppSelector } from '../../../store'
import { tagApi } from '../../../api/api-wrapper'
import type { ShapeTagDtoRequest } from '../../../generated/backend'
import { TOOLS } from '../../header/tools/consts'

const TagDraft: FC = () => {
	const [value, setValue] = useState<string>('')
	const { tagDraft } = useAppSelector(state => state.draw.stage)
	const { image, applicationMode } = useAppSelector(state => state.draw)
	const dispatch = useAppDispatch()

	if (!tagDraft.position) {
		return
	}

	const onPressEnter = async (): Promise<void> => {
		if (tagDraft.position && tagDraft.relatedShapeId && tagDraft.shapePosition && applicationMode && image.id) {
			const tag: ShapeTagDtoRequest = {
				x: tagDraft.shapePosition.x,
				y: tagDraft.shapePosition.y,
				shape_id: tagDraft.relatedShapeId,
				color: '#000000',
				label: value,
				rotate: 0,
			}
			await tagApi.createShapeTagApiShapeTagPost(tag)
			await dispatch(getAppData())
			dispatch(
				setTagDraft({
					position: null,
					shapeId: null,
					shapePosition: null,
				})
			)
			setValue('')
			dispatch(setSelectedTool(TOOLS.DEFAULT))
		}
	}

	return (
		<ConfigProvider
			theme={{
				components: {
					Input: {
						borderRadius: 0,
					},
				},
			}}
		>
			<Input
				style={{
					position: 'absolute',
					top: `${tagDraft.position.y}px`,
					left: `${tagDraft.position.x}px`,
					width: '200px',
					boxShadow: '2px 2px 20px 2px rgb(149 149 149)',
					padding: '1px',
					backgroundColor: 'white',
				}}
				value={value}
				onChange={e => {
					setValue(e.target.value)
				}}
				onPressEnter={onPressEnter}
			/>
		</ConfigProvider>
	)
}

export default TagDraft
