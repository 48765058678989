import { type ElementRef, type MutableRefObject } from 'react'
import { type Stage } from 'react-konva'
import { type Selection } from '../useDraw'
import Konva from 'konva'
import { getPosition } from '../../../../helpers'
import type { useAppDispatch } from '../../../../store'
import { setSelectedShapes } from '../../../../store'
import type { AppShape } from '../../../../store/slice/drawSlice'

const updateSelectionRect = (stage: ElementRef<typeof Stage>, selection: MutableRefObject<Selection>): void => {
	const selectionRectangle = stage.findOne('.SELECTION_RECT')
	if (selectionRectangle) {
		selectionRectangle.setAttrs({
			visible: selection.current.visible,
			x: Math.min(selection.current.x1, selection.current.x2),
			y: Math.min(selection.current.y1, selection.current.y2),
			width: Math.abs(selection.current.x1 - selection.current.x2),
			height: Math.abs(selection.current.y1 - selection.current.y2),
			fill: 'rgba(0, 161, 255, 0.3)',
		})
	}
}

export const startSelectArea = (
	e: Konva.KonvaEventObject<MouseEvent>,
	stage: ElementRef<typeof Stage>,
	selection: MutableRefObject<Selection>
) => {
	const position = getPosition(e)
	const isElement = e.target.findAncestor('.elements-container')
	const isTransformer = e.target.findAncestor('Transformer')

	if (isElement || isTransformer) {
		return
	}

	selection.current.visible = true
	selection.current.x1 = position.x
	selection.current.y1 = position.y
	selection.current.x2 = position.x
	selection.current.y2 = position.y
	updateSelectionRect(stage, selection)
}

export const selectingArea = (
	e: Konva.KonvaEventObject<MouseEvent>,
	stage: ElementRef<typeof Stage>,
	selection: MutableRefObject<Selection>
): void => {
	const currentPos = getPosition(e)
	selection.current.x2 = currentPos.x
	selection.current.y2 = currentPos.y
	updateSelectionRect(stage, selection)
}

export const finishSelectArea =
	(stage: ElementRef<typeof Stage>, selection: MutableRefObject<Selection>) =>
	(dispatch: ReturnType<typeof useAppDispatch>) => {
		const selectionRectangle = stage.findOne('.SELECTION_RECT')
		selection.current.visible = false
		const { x1, x2, y1, y2 } = selection.current
		const moved = x1 !== x2 || y1 !== y2

		if (!moved) {
			updateSelectionRect(stage, selection)
			return
		}

		const selectionBox = selectionRectangle?.getClientRect()
		const selectedShapes: AppShape[] = []
		stage.find('.SHAPE').forEach(elementNode => {
			const elementBox = elementNode.getClientRect()
			if (selectionBox && elementNode && Konva.Util.haveIntersection(selectionBox, elementBox)) {
				selectedShapes.push(elementNode.attrs.entity as AppShape)
			}
		})

		dispatch(setSelectedShapes(selectedShapes))
	}
