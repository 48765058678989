// extracted by mini-css-extract-plugin
var _1 = "ant-col-rtl_f408";
var _2 = "authDialog_ee82";
var _3 = "button_e83b";
var _4 = "container_b132";
var _5 = "content_c33b";
var _6 = "firstInput_ff58";
var _7 = "forgot_a9e5";
var _8 = "innerContent_b86f";
var _9 = "loginForm_fa83";
var _a = "logo_e69a";
var _b = "secondInput_b248";
export { _1 as "ant-col-rtl", _2 as "authDialog", _3 as "button", _4 as "container", _5 as "content", _6 as "firstInput", _7 as "forgot", _8 as "innerContent", _9 as "loginForm", _a as "logo", _b as "secondInput" }
