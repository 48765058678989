import { createSlice, type PayloadAction, type Action } from '@reduxjs/toolkit';
import { ApplicationMode } from '../../../types';
import type { Vector2d } from 'konva/lib/types';
import { getAppData } from '../actions/common/getAppData';
import { TOOLS } from '../../components/header/tools/consts';
import { ZOOM_STEP } from '../../../consts';
import { type IShape } from './types';
import { finishDraw } from '../actions/shapes/shapeActions';
import type {
  ConnectionDto,
  ShapeDtoRequest,
  ShapeTagDto
} from '../../generated/backend';
import type { DiagramComponentMapping } from '../actions/common/types';

export interface IShapeDraft extends ShapeDtoRequest {
  connections: ConnectionDto[];
}

export type AppShape = IShape<IInitialState['applicationMode']>

export interface IInitialState {
  image: {
    id: string | null;
    loading: boolean;
    error: boolean;
  };
  imageLayer: {
    zoom: Vector2d;
  };
  stage: {
    selectedTool: TOOLS;
    zoom: Vector2d;
    minimalZoom: Vector2d;
    position: Vector2d;
    draft: IShapeDraft | null;
    tagDraft: {
      position: Vector2d | null;
      relatedShapeId: string | null;
      shapePosition: Vector2d | null;
    };
    shapes: {
      lines: AppShape[];
      rectangles: AppShape[];
      connectors: ConnectionDto[];
      tags: ShapeTagDto[];
    },
    contextMenuPosition: Vector2d | null;
    selectedShapes: AppShape[];
    diagramComponents: DiagramComponentMapping<IInitialState['applicationMode']> | null;
    hoveringDiagramComponent: string | null;
    invisibleEntitiesId: string[];
  },
  diagramComponents: DiagramComponentMapping<IInitialState['applicationMode']> | null;
  applicationMode: ApplicationMode;
  errors: string[];
}

interface RejectedAction extends Action {
  error: Error;
  payload: {
    error: {
      message: string;
    }
  };
}

const isRejectedAction = (action: RejectedAction): action is RejectedAction => {
  return action.type.endsWith('rejected');
};

const initialState = {
  image: {
    id: null,
    loading: true,
    error: false,
  },
  imageLayer: {
    zoom: {
      x: 1,
      y: 1,
    },
  },
  stage: {
    selectedTool: TOOLS.DEFAULT,
    zoom: { x: 1, y: 1 },
    minimalZoom: { x: 1, y: 1 },
    position: { x: 0, y: 0 },
    draft: null,
    tagDraft: {
      position: null,
      relatedShapeId: null,
      shapePosition: null,
    },
    shapes: {
      lines: [],
      rectangles: [],
      connectors: [],
      tags: [],
    },
    diagramComponents: null,
    contextMenuPosition: null,
    selectedShapes: [],
    hoveringDiagramComponent: null,
    invisibleEntitiesId: [],
  },
  diagramComponents: null,
  applicationMode: ApplicationMode.DEMO_APP,
  errors: [],
} satisfies IInitialState as IInitialState;

const drawSlice = createSlice({
  name: 'drawApp',
  initialState,
  reducers: {
    setApplicationMode: (state, action: PayloadAction<ApplicationMode>) => {
      state.applicationMode = action.payload;
    },
    setImageId: (state, action: PayloadAction<string>) => {
      state.image.id = action.payload;
    },
    setImageLoaded: (state, action: PayloadAction<{ loading: boolean, error: boolean }>) => {
      state.image.loading = action.payload.loading;
      state.image.error = action.payload.error;
    },
    setImageScale: (state, action: PayloadAction<Vector2d>) => {
      state.imageLayer.zoom = action.payload;
      state.stage.minimalZoom = action.payload;
    },
    setSelectedTool: (state, action: PayloadAction<TOOLS>) => {
      state.stage.selectedTool = action.payload;
    },
    zoomIn: (state) => {
      state.stage.zoom = {
        x: state.stage.zoom.x + ZOOM_STEP,
        y: state.stage.zoom.y + ZOOM_STEP,
      };
    },
    zoomOut: (state) => {
      const newZoom = {
        x: state.stage.zoom.x - ZOOM_STEP,
        y: state.stage.zoom.x - ZOOM_STEP,
      };
      state.stage.zoom = {
        x: newZoom.x > state.stage.minimalZoom.x ? newZoom.x : state.stage.minimalZoom.x,
        y: newZoom.y > state.stage.minimalZoom.y ? newZoom.y : state.stage.minimalZoom.y,
      };
    },
    startDraw: (state, action: PayloadAction<IShapeDraft>) => {
      state.stage.draft = action.payload;
    },
    drawingShape: (state, action: PayloadAction<Pick<IShapeDraft, 'width' | 'height'>>) => {
      if (state.stage.draft) {
        state.stage.draft.width = action.payload.width;
        state.stage.draft.height = action.payload.height;
      }
    },
    setSelectedShapes: <T extends IInitialState>(state: T, action: PayloadAction<Array<IShape<typeof state.applicationMode>>>) => {
      state.stage.selectedShapes = action.payload;
    },
    setStageAttrs: (state, action: PayloadAction<{ scale: Vector2d, position: Vector2d }>) => {
      state.stage.zoom = action.payload.scale;
      state.stage.position = action.payload.position;
    },
    setContextMenuPosition: (state, action: PayloadAction<Vector2d | null>) => {
      state.stage.contextMenuPosition = action.payload;
    },
    setHoveringDiagramComponent: (state, action: PayloadAction<string | null>) => {
      state.stage.hoveringDiagramComponent = action.payload;
    },
    setTagDraft: (state, action: PayloadAction<{
      position: Vector2d | null;
      shapeId: string | null,
      shapePosition: Vector2d | null
    }>) => {
      state.stage.tagDraft.position = action.payload.position;
      state.stage.tagDraft.relatedShapeId = action.payload.shapeId;
      state.stage.tagDraft.shapePosition = action.payload.shapePosition;
    },
    addInvisibleId: (state, action: PayloadAction<string>) => {
      state.stage.invisibleEntitiesId = [...state.stage.invisibleEntitiesId, action.payload];
    },
    removeInvisibleId: (state, action: PayloadAction<string>) => {
      state.stage.invisibleEntitiesId = state.stage.invisibleEntitiesId.filter((id) => id !== action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAppData.fulfilled, (state, action) => {
        state.stage.shapes.lines = action.payload.lines;
        state.stage.shapes.rectangles = action.payload.rectangles;
        state.stage.shapes.connectors = action.payload.connectors;
        state.diagramComponents = action.payload.diagramComponents;
        state.stage.diagramComponents = action.payload.diagramComponentsOnDraw;
        state.stage.shapes.tags = action.payload.tags;
      })
      .addCase(finishDraw.fulfilled, (state) => {
        state.stage.draft = null;
      })
      .addMatcher(
        isRejectedAction,
        (state, action) => {
          state.errors.push(action.payload.error?.message);
        }
      )
  },
});

export default drawSlice.reducer;

export const {
  setApplicationMode,
  setImageId,
  setImageLoaded,
  setImageScale,
  setSelectedTool,
  setStageAttrs,
  zoomIn,
  zoomOut,
  startDraw,
  drawingShape,
  setSelectedShapes,
  setContextMenuPosition,
  setHoveringDiagramComponent,
  setTagDraft,
  addInvisibleId,
  removeInvisibleId,
} = drawSlice.actions;
