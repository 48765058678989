import React, { type FC, useEffect, useState } from 'react';
import * as style from './style.module.css';
import { useAppSelector } from '../../store';
import DEMOEntityList from './lists/demo/DEMOEntityList';
import RBILists from './lists/RBILists';

const Sider: FC = () => {
  const [title, setTitle] = useState<string>('');
  const { applicationMode } = useAppSelector((state) => state.draw);

  useEffect(() => {
    if (applicationMode === 'DEMO_APP') {
      setTitle('Разметка');
    }
    if (applicationMode === 'HAZOP_APP') {
      setTitle('Оборудование');
    }
  }, [applicationMode]);

  const renderLists = (): React.JSX.Element => {
    if (applicationMode === 'DEMO_APP' || applicationMode === 'HAZOP_APP') {
      return <DEMOEntityList title={title} />
    } else {
      return <RBILists />
    }
  };

  return (
    <div className={style.sider}>
      {renderLists()}
    </div>
  );
};

export default Sider;
