import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { getAppData, useAppDispatch, useAppSelector } from '../../../store'
import type { DiagramComponent } from '../../../generated/backend'
import { DiagramComponentType } from '../../../generated/backend'
import { shapeApi } from '../../../api/api-wrapper'
import type { Option } from './helpers'
import { diagramComponentToOption } from './helpers'
import type { HazopDiagramComponentMapping } from '../../../store/actions/common/types'

const HazopSelectors: FC = () => {
	const diagramComponents = useAppSelector(state => state.draw.diagramComponents as HazopDiagramComponentMapping)
	const { selectedShapes } = useAppSelector(state => state.draw.stage)
	const { applicationMode, image } = useAppSelector(state => state.draw)
	const [selectedEquip, setSelectedEquip] = useState<DiagramComponent | null>(null)
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (selectedShapes.length > 0) {
			const equips = new Set<DiagramComponent>()
			selectedShapes.forEach(shape => {
				if (shape.entities) {
					const { HazopEquipment } = shape.entities as HazopDiagramComponentMapping
					if (HazopEquipment.length === 1) {
						equips.add(HazopEquipment[0])
					}
				}
			})

			if (equips.size === 1) {
				const symbol = equips.values().next().value as DiagramComponent
				setSelectedEquip(symbol)
			}
		}
	}, [selectedShapes])

	const onSelect = async (value: string, option: Option): Promise<void> => {
		setSelectedEquip(option)
		if (applicationMode && image.id) {
			await Promise.all(
				selectedShapes.map(shape => {
					return shapeApi.linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(
						shape.id,
						value,
						DiagramComponentType.HazopEquipment
					)
				})
			)
			await dispatch(getAppData())
		}
	}

	if (!diagramComponents) {
		return
	}

	return (
		<Select
			style={{
				width: '320px',
			}}
			placeholder='Оборудование'
			options={diagramComponents.HazopEquipment.map(diagramComponentToOption)}
			value={selectedEquip ? { value: selectedEquip.id } : null}
			onSelect={onSelect}
			optionFilterProp='name'
			showSearch
		/>
	)
}

export default HazopSelectors
