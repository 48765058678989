import React, { type FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import UploadModal from './upload/UploadModal';
import DrawApp from '../lib';
import { Login } from './Login';

const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/drawing/:id' element={<DrawApp applicationMode='DEMO_APP' />} />
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<UploadModal />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
