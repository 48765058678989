import React, { type FC, type ReactNode } from 'react';
import { Button } from 'antd';
import { useAppSelector } from '../../../store';
import { type TOOLS } from './consts';
import * as styles from './style.module.css';

interface IToolButtonProps {
  onClick: () => void;
  icon: ReactNode;
  title: string;
  tool: TOOLS;
}

const ToolButton: FC<IToolButtonProps> = ({
                                            onClick,
                                            icon,
                                            title,
                                            tool,
                                          }) => {
  const { selectedTool } = useAppSelector((state) => state.draw.stage);
  return (
    <Button
      onClick={onClick}
      title={title}
      icon={icon}
      size="large"
      styles={{
        icon: {
          color: 'white',
        },
      }}
      className={selectedTool === tool ? styles.active : ''}
    />
  );
};

export default ToolButton;
