import { type Vector2d } from 'konva/lib/types'
import Konva from 'konva'
import { DIFF_TO_SNAP } from '../../../../../consts'
import LineConfig = Konva.LineConfig
import {
	GUIDE_LINE_NAME,
	LineGuideColor,
	LineGuideDash,
	LineGuideStrokeWidth,
	Orientation,
	SnapPosition,
} from './consts'

interface LineGuidesStopsPoint {
	vertical: number[]
	horizontal: number[]
}

interface SnappingBound {
	guide: number
	offset: number
	snapPosition: SnapPosition
}

interface ShapeSnappingBounds {
	vertical: SnappingBound[]
	horizontal: SnappingBound[]
}

interface SnapPoint {
	lineGuidePoint: number
	diff: number
	snapPosition: SnapPosition
	offset: number
}

interface ClosestSnapPoints {
	vertical: SnapPoint | null
	horizontal: SnapPoint | null
}

interface Guide {
	lineGuidePoint: number
	offset: number
	orientation: Orientation
	snapPosition: SnapPosition
}

type GetLineGuideStopsFromStage = (stage: Konva.Stage) => LineGuidesStopsPoint

type GetLineGuideStopsFromShapes = (shapes: Konva.Node[], draggedShape: Konva.Shape) => LineGuidesStopsPoint

type GetLineGuideStops = (draggedShape: Konva.Shape) => LineGuidesStopsPoint

type GetObjectSnappingBounds = (draggedShape: Konva.Shape) => ShapeSnappingBounds

type GetClosestSnapPoints = (
	lineGuideStops: LineGuidesStopsPoint,
	shapeBounds: ShapeSnappingBounds
) => ClosestSnapPoints

type GetGuides = (draggedShape: Konva.Shape) => Guide[]

type DrawGuideLines = (guides: Guide[], layer: Konva.Layer) => void

type UpdateLineGuidesPosition = (currentPosition: Vector2d, guideLine: Guide) => Vector2d

// points - это массив вида [x1, y1, x2, y2...]
export const VerticalGuideLine: Partial<LineConfig> = {
	points: [0, -6000, 0, 6000],
	stroke: LineGuideColor,
	dash: LineGuideDash,
	name: GUIDE_LINE_NAME,
	strokeWidth: LineGuideStrokeWidth,
}

export const HorizontalGuideLine: LineConfig = {
	points: [-6000, 0, 6000, 0],
	stroke: LineGuideColor,
	dash: LineGuideDash,
	name: GUIDE_LINE_NAME,
	strokeWidth: LineGuideStrokeWidth,
}

const getLineGuideStopsFromStage: GetLineGuideStopsFromStage = stage => {
	const verticalGuides = [0, stage.width() / 2, stage.width()]
	const horizontalGuides = [0, stage.height() / 2, stage.height()]

	return {
		vertical: verticalGuides,
		horizontal: horizontalGuides,
	}
}

const getLineGuideStopsFromShapes: GetLineGuideStopsFromShapes = (shapes, draggedShape) => {
	const verticalStops: number[] = []
	const horizontalStops: number[] = []

	shapes.forEach(shape => {
		if (shape === draggedShape) {
			return
		}

		const shapeBox = shape.getClientRect()
		verticalStops.push(
			shapeBox.x + DIFF_TO_SNAP,
			shapeBox.x + shapeBox.width / 2,
			shapeBox.x + shapeBox.width - DIFF_TO_SNAP
		)
		horizontalStops.push(
			shapeBox.y + DIFF_TO_SNAP,
			shapeBox.y + shapeBox.height / 2,
			shapeBox.y + shapeBox.height - DIFF_TO_SNAP
		)
	})

	return {
		vertical: verticalStops,
		horizontal: horizontalStops,
	}
}

const getLineGuideStops: GetLineGuideStops = draggedShape => {
	const stage = draggedShape.getStage()
	if (!stage)
		return {
			vertical: [],
			horizontal: [],
		}

	const allShapesOnStage = stage.find(`.SHAPE`)

	const stopsPointsFromStage = getLineGuideStopsFromStage(stage)
	const stopsPointsFromShapes = getLineGuideStopsFromShapes(allShapesOnStage, draggedShape)

	return {
		vertical: [...stopsPointsFromShapes.vertical, ...stopsPointsFromStage.vertical],
		horizontal: [...stopsPointsFromShapes.horizontal, ...stopsPointsFromStage.horizontal],
	}
}

// снимаем точки "залипания" с перемещаемой фигуры
// с каждой оси забираем начало, середину и конец фигуры
const getObjectSnappingBounds: GetObjectSnappingBounds = draggedShape => {
	const shapeBox = draggedShape.getClientRect()
	const absolutePosition = draggedShape.absolutePosition()

	return {
		vertical: [
			{
				guide: Math.round(shapeBox.x),
				offset: Math.round(absolutePosition.x - shapeBox.x),
				snapPosition: SnapPosition.START,
			},
			{
				guide: Math.round(shapeBox.x + shapeBox.width / 2),
				offset: Math.round(absolutePosition.x - shapeBox.x - shapeBox.width / 2),
				snapPosition: SnapPosition.CENTER,
			},
			{
				guide: Math.round(shapeBox.x + shapeBox.width),
				offset: Math.round(absolutePosition.x - shapeBox.x - shapeBox.width),
				snapPosition: SnapPosition.END,
			},
		],
		horizontal: [
			{
				guide: Math.round(shapeBox.y),
				offset: Math.round(absolutePosition.y - shapeBox.y),
				snapPosition: SnapPosition.START,
			},
			{
				guide: Math.round(shapeBox.y + shapeBox.height / 2),
				offset: Math.round(absolutePosition.y - shapeBox.y - shapeBox.height / 2),
				snapPosition: SnapPosition.CENTER,
			},
			{
				guide: Math.round(shapeBox.y + shapeBox.height),
				offset: Math.round(absolutePosition.y - shapeBox.y - shapeBox.height),
				snapPosition: SnapPosition.END,
			},
		],
	}
}

const getClosestSnapPoints: GetClosestSnapPoints = (lineGuideStops, draggedShapeBounds) => {
	const verticalResult: SnapPoint[] = []
	const horizontalResult: SnapPoint[] = []

	lineGuideStops.vertical.forEach(lineGuide => {
		draggedShapeBounds.vertical.forEach(shapeBound => {
			const diff = Math.abs(lineGuide - shapeBound.guide)
			if (diff < DIFF_TO_SNAP) {
				verticalResult.push({
					lineGuidePoint: lineGuide,
					diff,
					snapPosition: shapeBound.snapPosition,
					offset: shapeBound.offset,
				})
			}
		})
	})

	lineGuideStops.horizontal.forEach(lineGuide => {
		draggedShapeBounds.horizontal.forEach(shapeBound => {
			const diff = Math.abs(lineGuide - shapeBound.guide)
			if (diff < DIFF_TO_SNAP) {
				horizontalResult.push({
					lineGuidePoint: lineGuide,
					diff,
					snapPosition: shapeBound.snapPosition,
					offset: shapeBound.offset,
				})
			}
		})
	})

	verticalResult.sort((a, b) => a.diff - b.diff)
	horizontalResult.sort((a, b) => a.diff - b.diff)

	const closestVerticalPoint = verticalResult[0]
	const closestHorizontalPoint = horizontalResult[0]

	return {
		vertical: closestVerticalPoint || null,
		horizontal: closestHorizontalPoint || null,
	}
}

const getGuides: GetGuides = movingShape => {
	const lineGuideStops = getLineGuideStops(movingShape)
	const movingShapeBounds = getObjectSnappingBounds(movingShape)
	const points = getClosestSnapPoints(lineGuideStops, movingShapeBounds)
	const guides: Guide[] = []

	if (points.vertical) {
		guides.push({
			lineGuidePoint: points.vertical.lineGuidePoint,
			offset: points.vertical.offset,
			orientation: Orientation.VERTICAL,
			snapPosition: points.vertical.snapPosition,
		})
	}

	if (points.horizontal) {
		guides.push({
			lineGuidePoint: points.horizontal.lineGuidePoint,
			offset: points.horizontal.offset,
			orientation: Orientation.HORIZONTAL,
			snapPosition: points.horizontal.snapPosition,
		})
	}

	return guides
}

const drawGuidesLines: DrawGuideLines = (guides, layer) => {
	guides.forEach(guide => {
		if (guide.orientation === Orientation.HORIZONTAL) {
			const line = new Konva.Line(HorizontalGuideLine)
			layer.add(line)
			line.absolutePosition({
				x: 0,
				y: guide.lineGuidePoint,
			})
		} else {
			const line = new Konva.Line(VerticalGuideLine)
			layer.add(line)
			line.absolutePosition({
				x: guide.lineGuidePoint,
				y: 0,
			})
		}
	})
}

const updateLineGuidePosition: UpdateLineGuidesPosition = (currentPosition, guideLine) => {
	const updatedPosition = { ...currentPosition }

	if (guideLine.orientation === Orientation.VERTICAL) {
		updatedPosition.x = guideLine.lineGuidePoint + guideLine.offset
	} else {
		updatedPosition.y = guideLine.lineGuidePoint + guideLine.offset
	}

	return updatedPosition
}

export const onDragMove = (e: Konva.KonvaEventObject<DragEvent>): void => {
	const layer = e.target.getLayer()
	const lines = layer?.find(`.${GUIDE_LINE_NAME}`)
	const stage = e.target.getStage()

	if (stage) {
		stage.container().style.cursor = 'grabbing'
	}

	if (lines) {
		for (const line of lines) {
			line.destroy()
		}
	}

	const guides = getGuides(e.target as Konva.Shape)

	if (guides.length > 0 && layer) {
		drawGuidesLines(guides, layer)

		const absolutePosition = e.target.absolutePosition()
		guides.forEach(lineGuide => {
			const newPosition = updateLineGuidePosition(absolutePosition, lineGuide)
			absolutePosition.x = newPosition.x
			absolutePosition.y = newPosition.y
		})
		// e.target.absolutePosition(absolutePosition);
	}
}
