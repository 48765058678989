import type Konva from 'konva';
import type { Vector2d } from 'konva/lib/types';
import { notification } from 'antd';

export const getPosition = (e: Konva.KonvaEventObject<MouseEvent>): Vector2d => {
  const stage = e.target.getStage();
  const stageTransform = stage?.getAbsoluteTransform().copy();
  const pointerPosition = stage?.getPointerPosition();

  if (pointerPosition?.x && stageTransform) {
    return stageTransform?.invert().point(pointerPosition);
  }

  return { x: 0, y: 0 };
};

export const showNotification = (message: string, description: string): void => {
  notification.error({
    message,
    description,
    duration: 3,
  })
};
