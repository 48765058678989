import React, { type FC } from 'react';
import * as styles from './style.module.css';
import ToolButton from './ToolButton';
import {
  AimOutlined,
  ArrowLeftOutlined,
  BorderOutlined,
  EditOutlined,
  FontSizeOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { TOOLS } from './consts';
import { useDispatch } from 'react-redux';
import { setSelectedTool, zoomIn, zoomOut } from '../../../store';

const ToolsBar: FC = () => {
  const dispatch = useDispatch();

  const onClick = (tool: TOOLS): void => {
    dispatch(setSelectedTool(tool));
  };

  return (
    <div className={styles.tools}>
      <div>
        <ToolButton
          onClick={() => { onClick(TOOLS.DEFAULT); }}
          icon={<ArrowLeftOutlined rotate={45} />}
          title=""
          tool={TOOLS.DEFAULT}
        />
        <ToolButton
          onClick={() => { onClick(TOOLS.LINE); }}
          icon={<EditOutlined />}
          title="Нанести линию"
          tool={TOOLS.LINE}
        />
        <ToolButton
          onClick={() => { onClick(TOOLS.RECTANGLE); }}
          icon={<BorderOutlined />}
          title={"Нанести прямоугольник"}
          tool={TOOLS.RECTANGLE}
        />
        <ToolButton
          onClick={() => { onClick(TOOLS.TAG); }}
          icon={<FontSizeOutlined />}
          title={"Нанести тег"}
          tool={TOOLS.TAG}
        />
      </div>

      <div>
        <ToolButton
          onClick={() => { dispatch(zoomIn()) }}
          icon={<ZoomInOutlined />}
          title="Увеличить"
          tool={TOOLS.ZOOM_IN}
        />
        <ToolButton
          onClick={() => { dispatch(zoomOut()) }}
          icon={<ZoomOutOutlined />}
          title="Уменьшить"
          tool={TOOLS.ZOOM_OUT}
        />
      </div>

      <div>
        <ToolButton
          onClick={() => { onClick(TOOLS.SELECT); }}
          icon={<AimOutlined />}
          title="Выбрать область"
          tool={TOOLS.SELECT}
        />
      </div>
    </div>
  );
};

export default ToolsBar;
