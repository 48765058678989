import React, { type FC } from 'react';
import { Rect } from 'react-konva';

const SelectionRectangle: FC = () => {
  return (
    <Rect
      fill="rgba(0,0,255,0.5)"
      name="SELECTION_RECT"
    />
  );
};

export default SelectionRectangle;
