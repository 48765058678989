import type Konva from 'konva';
import { setTagDraft, type useAppDispatch } from '../../../store';

export const createTag = (e: Konva.KonvaEventObject<MouseEvent>) => (dispatch: ReturnType<typeof useAppDispatch>) => {
  const shapeEntity = e.target;
  const stage = e.target.getStage();

  if (shapeEntity.attrs.entity?.tags?.length) {
    return;
  }

  if (shapeEntity?.attrs.shapeType && stage) {
    const inputPosition = {
      x: stage.container().offsetLeft + shapeEntity.absolutePosition().x,
      y: stage.container().offsetTop + shapeEntity.absolutePosition().y - 30,
    };
    const tagPosition = {
      x: e.target.attrs.x,
      y: e.target.attrs.y - 30,
    };
    dispatch(setTagDraft({
      position: inputPosition,
      shapeId: shapeEntity.attrs.id,
      shapePosition: tagPosition,
    }));
  }
};
