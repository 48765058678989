import React, { type FC } from 'react';
import { Modal } from 'antd';
import ImageTable from './PDFTable/ImageTable';
import UploadPdf from './UploadPdf';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAuth } from '../../utils';

const UploadModal: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const checkAuth = async (): Promise<void> => {
      const auth = await isAuth();

      if (!auth) {
        navigate('/login', { state: { from: location.pathname } });
      }
    };

    void checkAuth();
  }, []);

  return (
    <>
      <Modal open={true} closable={false} footer={null}>
        <ImageTable />
        <UploadPdf />
      </Modal>
    </>
  );
};

export default UploadModal;
