import type { RcFile } from 'antd/es/upload'
import {
	type ChunkUploadData,
	FileApiFactory,
	type FileDto,
	type MultipartUploadData,
} from '../lib/src/generated/backend'
import type { UploadProgressEvent } from 'rc-upload/lib/interface'

interface ChunkUploadParams {
	chunk: File
	chunkNumber: number
	uploadId: string
	fileKey: string
}

interface FinishChunkUploadParams {
	fileName: string
	uploadId: string
	fileKey: string
	allChunks: ChunkUploadData[]
}

const fileApi = FileApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

const startChunkUpload = async (): Promise<MultipartUploadData> => {
	try {
		return (await fileApi.uploadStartApiFileUploadStartPost()).data
	} catch (error) {
		console.error(error)
		throw Error(error as string)
	}
}

const uploadChunk = async ({ chunk, chunkNumber, uploadId, fileKey }: ChunkUploadParams): Promise<ChunkUploadData> => {
	try {
		return (await fileApi.uploadChunkApiFileUploadChunkPost(chunkNumber, uploadId, fileKey, chunk)).data
	} catch (error) {
		console.error(error)
		throw Error(error as string)
	}
}

const finishChunkUpload = async ({
	fileName,
	uploadId,
	fileKey,
	allChunks,
}: FinishChunkUploadParams): Promise<FileDto> => {
	try {
		return (await fileApi.uploadFinishApiFileUploadFinishPost(uploadId, fileKey, fileName, allChunks)).data
	} catch (error) {
		console.error(error)
		throw Error(error as string)
	}
}

export const uploadFile = async (file: RcFile, onProgress: (event: UploadProgressEvent) => void): Promise<void> => {
	if (!file) return

	const allChunks: ChunkUploadData[] = []

	const { file_key: key, upload_id: uploadId } = await startChunkUpload()

	if (!key || !uploadId) return

	const chunkSize = 1024 * 1024 * 5
	const totalChunks = Math.ceil(file.size / chunkSize)

	let start = 0
	let end = Math.min(chunkSize, file.size)

	for (let i = 1; i <= totalChunks; i++) {
		onProgress({ percent: (i / totalChunks) * 100 })

		const chunk = file.slice(start, end) as File

		try {
			const responseChunk = await uploadChunk({ chunk, chunkNumber: i, uploadId, fileKey: key })

			allChunks.push(responseChunk)
		} catch (error) {
			console.error(error)
			break
		}

		start = end
		end = Math.min(start + chunkSize, file.size)
	}

	await finishChunkUpload({ fileKey: key, fileName: file.name, uploadId, allChunks })
}
