import { loginApi } from '../api/api-wrapper';

export const isAuth = async (): Promise<boolean> => {
  try {
    await loginApi.getMeApiUsersMeGet();
    return true;
  } catch (e) {
    return false;
  }
};
