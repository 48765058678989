import React, { type FC, useEffect, useRef, useState } from 'react'
import { setImageLoaded, useAppDispatch, useAppSelector, setImageScale } from '../../store'
import { Buffer } from 'buffer'
import { Image, Layer } from 'react-konva'
import axios from 'axios'

interface IImageLayer {
	stageContainer: HTMLDivElement | null
}

const ImageLayer: FC<IImageLayer> = ({ stageContainer }) => {
	const { id } = useAppSelector(state => state.draw.image)
	const [imageFile, setImageFile] = useState<HTMLImageElement>()
	const dispatch = useAppDispatch()
	const imageNode = useRef<React.ElementRef<typeof Image>>(null)

	useEffect(() => {
		if (!id) {
			return
		}

		const handleLoadImage = (): void => {
			const image = new window.Image()

			void axios
				.get(`/api/file/${id}/content`, {
					responseType: 'arraybuffer',
				})
				.then(res => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					const file = Buffer.from(res.data, 'binary').toString('base64')
					image.src = `data:image/jpg;base64,${file}`

					const handleLoad = (): void => {
						setImageFile(image)
						const layer = imageNode.current?.getLayer()
						layer?.batchDraw()
						dispatch(
							setImageLoaded({
								loading: false,
								error: false,
							})
						)
						image.removeEventListener('load', handleLoad)
					}

					image.addEventListener('load', handleLoad)
				})
				.catch(e => {
					console.error(e)
					dispatch(
						setImageLoaded({
							loading: false,
							error: true,
						})
					)
				})
		}

		handleLoadImage()
	}, [id])

	useEffect(() => {
		if (imageFile?.naturalWidth && imageFile?.naturalHeight && stageContainer) {
			const { naturalHeight, naturalWidth } = imageFile
			const scaleY = stageContainer.offsetHeight / naturalHeight
			const scaleX = stageContainer.offsetWidth / naturalWidth
			const scale = scaleY < scaleX ? scaleY : scaleX
			dispatch(setImageScale({ x: scale, y: scale }))
		}
	}, [imageFile])

	return (
		<Layer listening={false}>
			<Image image={imageFile} ref={imageNode} />
		</Layer>
	)
}

export default ImageLayer
