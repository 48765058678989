import React, { type FC, type ReactNode } from 'react';
import * as styles from './style.module.css';
import ToolsBar from './tools/ToolsBar';
import Pagination from './pagination/Pagination';

interface IHeader {
  logoutComponent?: ReactNode;
  uploadComponent?: ReactNode;
}

const Header: FC<IHeader> = ({ logoutComponent, uploadComponent }) => {
  return (
    <div className={styles.header}>
      <div>
        {logoutComponent}
        {uploadComponent}
      </div>
      <ToolsBar/>
      <Pagination/>
    </div>
  );
};

export default Header;
