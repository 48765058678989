import React, { type FC, type ReactNode, useEffect, useState } from 'react'
import { ConfigProvider } from 'antd'
import { themeConfig } from '../consts'
import * as classNames from './style.module.css'
import { Provider } from 'react-redux'
import { store } from './store'
import { ApplicationMode } from '../types'
import Drawing from './components/Drawing'
import { imageApi, pdfApi } from './api/api-wrapper'
import type { AxiosPromise } from 'axios'

interface IDrawApp {
	applicationMode: ApplicationMode
	logout?: ReactNode
	loader?: ReactNode
	checkAuth?: () => AxiosPromise<string>
}

const DrawApp: FC<IDrawApp> = ({ applicationMode, loader, logout, checkAuth }) => {
	const [isAuth, setIsAuth] = useState<boolean>(false)

	const checkAuthIsNeed = async (): Promise<void> => {
		if (applicationMode === ApplicationMode.DEMO_APP) {
			setIsAuth(true)
		} else if (checkAuth) {
			const response = await checkAuth()
			if (response.status === 200) {
				setIsAuth(true)
			} else {
				setIsAuth(false)
			}
		}
	}

	useEffect(() => {
		checkAuthIsNeed()
			.then()
			.catch(e => {
				console.error(e)
			})
	}, [applicationMode, checkAuth])

	// if (!applicationMode || !isAuth) {
	// 	return
	// }

	return (
		<Provider store={store}>
			<ConfigProvider theme={themeConfig}>
				<div className={classNames.drawApp}>
					<Drawing applicationMode={applicationMode} loader={loader} />
				</div>
			</ConfigProvider>
		</Provider>
	)
}

export default DrawApp
export { imageApi, pdfApi }
