import React, { type FC, useEffect, useState } from 'react'
import * as styles from './style.module.css'
import {
	addInvisibleId,
	removeInvisibleId,
	setHoveringDiagramComponent,
	useAppDispatch,
	useAppSelector,
} from '../../../../store'
import type { DiagramComponent } from '../../../../generated/backend'
import { LinkOutlined, TagOutlined } from '@ant-design/icons'
import { Button } from 'antd'

interface IListItem {
	item: DiagramComponent
	link?: string | undefined
	buttonTitle?: string
}

const ListItem: FC<IListItem> = ({ item, link, buttonTitle }) => {
	const { hoveringDiagramComponent } = useAppSelector(state => state.draw.stage)
	const [isHovering, setIsHovering] = useState<boolean>(false)
	const [isSelected, setIsSelected] = useState<boolean>(false)
	const [isVisible, setIsVisible] = useState<boolean>(true)
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (hoveringDiagramComponent === item.id) {
			setIsHovering(true)
		} else {
			setIsHovering(false)
		}
	}, [hoveringDiagramComponent])

	const onMouseOver = (): void => {
		if (item.id) {
			dispatch(setHoveringDiagramComponent(item.id))
		}
	}

	const onMouseLeave = (): void => {
		if (!isSelected) {
			dispatch(setHoveringDiagramComponent(null))
		}
	}

	const onClick = (): void => {
		if (isSelected) {
			setIsSelected(false)
			dispatch(setHoveringDiagramComponent(null))
		} else {
			setIsSelected(true)
			dispatch(setHoveringDiagramComponent(item.id))
		}
	}

	const switchVisible = (): void => {
		if (isSelected) {
			setIsVisible(true)
			dispatch(removeInvisibleId(item.id))
		} else {
			setIsVisible(false)
			dispatch(addInvisibleId(item.id))
		}
	}

	return (
		<li
			style={{
				border: '2px solid',
				borderColor: isHovering ? item.color : `${item.color}50`,
				backgroundColor: `${item.color}50`,
				display: 'flex',
				alignItems: 'center',
			}}
			className={styles.listItem}
			onMouseOver={onMouseOver}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
		>
			<p>{item.name}</p>
			<Button.Group>
				<Button
					onClick={switchVisible}
					type='link'
					icon={<TagOutlined />}
					styles={{
						icon: {
							color: isVisible ? '#1677ff' : 'gray',
						},
					}}
					title={isVisible ? 'Скрыть подписи с чертежа' : 'Отобразить подписи на чертеже'}
				/>
				{link && <Button type='link' href={link} target='_blank' icon={<LinkOutlined />} title={buttonTitle} />}
			</Button.Group>
		</li>
	)
}

export default ListItem
