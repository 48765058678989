import React, { type FC } from 'react';
import type {
  DemoDiagramComponentMapping,
  HazopDiagramComponentMapping,
  RbiDiagramComponentMapping
} from '../../../../store/actions/common/types';
import RbiEntitiesLabel from './RbiEntitiesLabel';
import type { Vector2d } from 'konva/lib/types';
import HazopEntitiesLabel from './HazopEntitiesLabel';
import DemoEntitiesLabel from './DemoEntitiesLabel';
import { ApplicationMode } from '../../../../../types';
// todo перевести на режим приложения
interface IEntitiesLabel {
  entities: RbiDiagramComponentMapping | HazopDiagramComponentMapping | DemoDiagramComponentMapping | null
  position: Vector2d
  rotate: number
  hovering: boolean
  appMode: ApplicationMode
}

const EntitiesLabel: FC<IEntitiesLabel> = ({ entities, position, rotate, hovering, appMode }) => {

  if (appMode === ApplicationMode.RBI_APP) {
    return <RbiEntitiesLabel hovering={hovering} rotate={rotate} position={position} entity={entities as RbiDiagramComponentMapping} />;
  }

  if ((entities as HazopDiagramComponentMapping).HazopNode?.length >= 0) {
    return <HazopEntitiesLabel hovering={hovering} rotate={rotate} position={position} entity={entities as HazopDiagramComponentMapping} />
  }

  if ((entities as DemoDiagramComponentMapping).Symbol?.length >= 0) {
    return <DemoEntitiesLabel hovering={hovering} rotate={rotate} position={position} entity={entities as DemoDiagramComponentMapping} />
  }
};

export default EntitiesLabel;
