import type Konva from 'konva';
import { TOOLS } from '../../../header/tools/consts';

export const onMouseOverChangeCursor = (e: Konva.KonvaEventObject<MouseEvent>, selectedTool: TOOLS): void => {
  const stage = e.target.getStage();
  const containerStyles = stage?.container().style;

  if (containerStyles) {
    if (selectedTool === TOOLS.TAG) {
      containerStyles.cursor = 'text';
    } else if (selectedTool === TOOLS.RECTANGLE || selectedTool === TOOLS.LINE) {
      containerStyles.cursor = 'crosshair';
    } else {
      containerStyles.cursor = 'pointer';
    }
  }
};
