import type Konva from 'konva';

export const onMouseLeaveChangeCursor = (e: Konva.KonvaEventObject<MouseEvent>): void => {
  const stage = e.target.getStage();
  const containerStyles = stage?.container().style;

  if (containerStyles) {
    containerStyles.cursor = 'default';
  }
}
