import React, { type FC } from 'react'
import { Empty } from 'antd'

interface IEmptyData {
  text?: string
}

const EmptyData: FC<IEmptyData> = ({ text = 'Нет данных' }) => (
  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={text} />
)

export default EmptyData
