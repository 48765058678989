import type Konva from 'konva'
import { drawingShape, setSelectedTool, startDraw, type useAppDispatch } from '../../../../store'
import { getPosition } from '../../../../helpers'
import { type IShapeDraft } from '../../../../store/slice/drawSlice'
import { type ShapeDtoRequest, ShapeType } from '../../../../generated/backend'
import { finishDraw } from '../../../../store/actions/shapes/shapeActions'
import { TOOLS } from '../../../header/tools/consts'
import { onDragEnd, onDragMove } from '../../../shapes/cleans/onDrag'

export const startDrawRect =
	(e: Konva.KonvaEventObject<MouseEvent>, imageId: string) => (dispatch: ReturnType<typeof useAppDispatch>) => {
		const startDrawingPosition = getPosition(e)

		const shapeDraft: IShapeDraft = {
			x: startDrawingPosition.x,
			y: startDrawingPosition.y,
			shape_type: ShapeType.Rectangle,
			image_drawing_id: imageId,
			connections: [],
		}

		dispatch(startDraw(shapeDraft))
	}

export const drawRect =
	(e: Konva.KonvaEventObject<MouseEvent>, shapeDraft: IShapeDraft) => (dispatch: ReturnType<typeof useAppDispatch>) => {
		const position = getPosition(e)
		if (shapeDraft.x && shapeDraft.y) {
			const newSize = {
				width: position.x - shapeDraft.x,
				height: position.y - shapeDraft.y,
			}

			dispatch(drawingShape(newSize))
		}
	}

export const finishDrawRect =
	(e: Konva.KonvaEventObject<MouseEvent>, draft: IShapeDraft) =>
	async (dispatch: ReturnType<typeof useAppDispatch>) => {
		const entityForCreate: ShapeDtoRequest = {
			height: draft.height,
			image_drawing_id: draft.image_drawing_id,
			rotate: 0,
			shape_type: draft.shape_type,
			width: draft.width,
			x: draft.x,
			y: draft.y,
		}

		await dispatch(finishDraw({ draft: entityForCreate, connections: [] }))
		dispatch(setSelectedTool(TOOLS.DEFAULT))
	}
