import React, { type FC, useEffect, useRef, useState } from 'react'
import { Rect } from 'react-konva'
import type { ConnectionDto, ShapeDto } from '../../../../generated/backend'
import { ShapeType } from '../../../../generated/backend'
import {
	getAppData,
	setContextMenuPosition,
	setHoveringDiagramComponent,
	setSelectedShapes,
	useAppDispatch,
	useAppSelector,
} from '../../../../store'
import Konva from 'konva'
import { connectionApi, shapeApi } from '../../../../api/api-wrapper'
import { onDragEnd, onDragMove } from '../onDrag'
import type { ApplicationMode } from '../../../../../consts'
import { DIFF_TO_SNAP } from '../../../../../consts'
import { getRelatedEntity } from '../helpers'
import { onMouseLeaveChangeCursor, onMouseOverChangeCursor } from '../onMouse'
import type { AppShape } from '../../../../store/slice/drawSlice'
import EntitiesLabel from '../EntitiesLabel'

interface IRectangle {
	rectangle: AppShape
}

const Rectangle: FC<IRectangle> = ({ rectangle }) => {
	const { applicationMode } = useAppSelector(state => state.draw)
	const { hoveringDiagramComponent, selectedTool } = useAppSelector(state => state.draw.stage)
	const rectRef = useRef<React.ElementRef<typeof Rect>>(null)
	const dispatch = useAppDispatch()
	const [rectIsHovering, setRectIsHovering] = useState(false)

	useEffect(() => {
		if (rectRef.current) {
			const entity = getRelatedEntity(applicationMode as ApplicationMode, rectangle)
			if (entity?.id === hoveringDiagramComponent) {
				rectRef.current.setAttr('stroke', rectangle.colors.active)
				setRectIsHovering(true)
			} else {
				rectRef.current.setAttr('stroke', rectangle.colors.regular)
				setRectIsHovering(false)
			}
		}
	}, [hoveringDiagramComponent])

	const onMouseOver = (e: Konva.KonvaEventObject<MouseEvent>): void => {
		const entity = getRelatedEntity(applicationMode as ApplicationMode, rectangle)
		if (entity?.id) {
			dispatch(setHoveringDiagramComponent(entity.id))
		}
		onMouseOverChangeCursor(e, selectedTool)
	}

	const onMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>): void => {
		dispatch(setHoveringDiagramComponent(null))
		onMouseLeaveChangeCursor(e)
	}

	const onClick = (): void => {
		dispatch(setContextMenuPosition(null))
		dispatch(setSelectedShapes([rectangle]))
	}

	const getNewAttrs = (e: Konva.KonvaEventObject<Event>): AppShape => {
		return {
			...rectangle,
			x: e.target.attrs.x,
			y: e.target.attrs.y,
			width: rectangle.width * e.target.attrs.scaleX,
			height: rectangle.height * e.target.attrs.scaleY,
			image_drawing_id: rectangle.image_drawing_id ?? '',
		}
	}

	const updateShape = async (rectangle: AppShape): Promise<ShapeDto> => {
		const entityForUpdate = {
			height: rectangle.height,
			image_drawing_id: rectangle.image_drawing_id,
			label: rectangle.label,
			points: rectangle.points,
			shape_type: rectangle.shape_type,
			width: rectangle.width,
			x: rectangle.x,
			y: rectangle.y,
			id: rectangle.id,
			rotate: rectangle.rotate,
		}

		const res = await shapeApi.updateShapeApiShapeShapeIdPut(entityForUpdate.id, entityForUpdate)
		return res.data
	}

	const getAllIntersections = (): string[] => {
		const connectedShapeIds: string[] = []
		const layer = rectRef?.current?.getLayer()
		const draggableRectangle = rectRef?.current?.getClientRect()
		if (layer && draggableRectangle) {
			const allShapesOnDraw = layer?.find(`.SHAPE`)
			for (const shape of allShapesOnDraw) {
				const shapeRect = shape.getClientRect()
				shapeRect.x = shapeRect.x - DIFF_TO_SNAP
				shapeRect.y = shapeRect.y - DIFF_TO_SNAP
				shapeRect.width = shapeRect.width + DIFF_TO_SNAP
				shapeRect.height = shapeRect.height + DIFF_TO_SNAP
				const shapeType = shape.attrs.shapeType
				if (shape.attrs.id !== rectangle.id && shapeType !== 'RECTANGLE') {
					const shapesHaveIntersection = Konva.Util.haveIntersection(shapeRect, draggableRectangle)
					if (shapesHaveIntersection) {
						connectedShapeIds.push(shape.attrs.id as string)
					}
				}
			}
		}

		return connectedShapeIds
	}

	const removeConnection = async (connection: ConnectionDto): Promise<void> => {
		for (const shapeId of connection.shapes_id) {
			await shapeApi.unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId, connection.id)
		}
		await connectionApi.deleteConnectionApiConnectionConnectionIdDelete(connection.id)
	}

	const updateConnection = async (connection: ConnectionDto, shapesId: string[]): Promise<void> => {
		const shapeIdsForDetach = connection.shapes_id?.reduce<string[]>((acc, id) => {
			if (id === rectangle.id) {
				return acc
			}
			const shapeIdStillHaveIntersection = shapesId.includes(id)
			if (shapeIdStillHaveIntersection) {
				return acc
			} else {
				return [...acc, id]
			}
		}, [])

		const shapeIdsForAttach = shapesId.reduce<string[]>((acc, id) => {
			if (id === rectangle.id) {
				return acc
			}
			const shapeIsNotAttached = !connection.shapes_id?.includes(id)
			if (shapeIsNotAttached) {
				return [...acc, id]
			} else {
				return acc
			}
		}, [])

		for (const id of shapeIdsForAttach) {
			await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(id, connection.id)
		}
		for (const id of shapeIdsForDetach) {
			await shapeApi.unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(id, connection.id)
		}
	}

	const createConnection = async (shapeIdForConnect: string[], rectangle: ShapeDto): Promise<void> => {
		const createdConnection = await connectionApi.createConnectionApiConnectionPost({
			x: rectangle.x + rectangle.width / 2,
			y: rectangle.y + rectangle.height / 2,
			color: 'blue',
			width: 15,
			height: 15,
		})
		await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(
			rectangle.id,
			createdConnection.data.id
		)
		for (const shapeId of shapeIdForConnect) {
			await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(
				shapeId,
				createdConnection.data.id
			)
		}
		await connectionApi.getConnectionApiConnectionConnectionIdGet(createdConnection.data.id)
	}

	const onTransformEnd = async (e: Konva.KonvaEventObject<Event>): Promise<void> => {
		const newRectAttrs = getNewAttrs(e)
		const updatedShape = await updateShape(newRectAttrs)
		const connectedShapes = getAllIntersections()
		const connection = rectangle.connections[0]

		if (connection && connectedShapes.length === 0) {
			await removeConnection(connection)
		}
		if (connection && connectedShapes.length > 0) {
			await updateConnection(connection, connectedShapes)
			try {
				await connectionApi.updateConnectionApiConnectionConnectionIdPut(connection.id, {
					x: updatedShape.x + updatedShape.width / 2,
					y: updatedShape.y + updatedShape.height / 2,
					id: connection.id,
					width: connection.width,
					height: connection.height,
					color: connection.color,
				})
			} catch (e) {
				console.error(e)
			}
		}
		if (!connection && connectedShapes.length > 0) {
			await createConnection(connectedShapes, updatedShape)
		}

		await dispatch(getAppData())
		e.target.setAttr('scaleX', 1)
		e.target.setAttr('scaleY', 1)
	}

	const renderEntitiesLabels = (): React.JSX.Element => {
		let x = rectangle.x
		let y = rectangle.y - 15
		const labels = {}
		if (rectangle.entitiesForLabels && rectangle.entitiesForLabels.length > 0) {
			for (const entity of rectangle.entitiesForLabels) {
				// @ts-ignore
				labels[entity.type] = [entity]
			}
		}
		return (
			<EntitiesLabel
				appMode={applicationMode}
				hovering={rectIsHovering}
				rotate={0}
				entities={labels}
				position={{ x, y }}
			/>
		)
	}

	return (
		<>
			<Rect
				id={rectangle.id}
				x={rectangle.x}
				y={rectangle.y}
				width={rectangle.width}
				height={rectangle.height}
				fill={rectangle.colors.regular}
				strokeWidth={5}
				stroke={rectangle.colors.active}
				shapeType={ShapeType.Rectangle}
				hitStrokeWidth={15}
				entity={rectangle}
				connections={rectangle.connections}
				name='SHAPE'
				onMouseOver={onMouseOver}
				onMouseLeave={onMouseLeave}
				onClick={onClick}
				ref={rectRef}
				onDragMove={e => {
					onDragMove(e)
				}}
				onDragEnd={async e => {
					onDragEnd(e)
					await onTransformEnd(e)
				}}
				onTransformEnd={onTransformEnd}
				draggable
			/>
			{renderEntitiesLabels()}
		</>
	)
}

export default Rectangle
