import React, { type FC, useEffect, useState } from 'react'
import { Select } from 'antd'
import { getAppData, useAppDispatch, useAppSelector } from '../../../store'
import { type DiagramComponent, DiagramComponentType } from '../../../generated/backend'
import { shapeApi } from '../../../api/api-wrapper'
import type { SelectHandler } from 'rc-select/lib/Select'
import type { DemoDiagramComponentMapping } from '../../../store/actions/common/types'

type Option = DiagramComponent & { label: string; value: string }

const DEMOSelectors: FC = () => {
	const diagramComponents = useAppSelector(state => state.draw.diagramComponents as DemoDiagramComponentMapping)
	const { selectedShapes } = useAppSelector(state => state.draw.stage)
	const { applicationMode, image } = useAppSelector(state => state.draw)
	const [selectedSymbol, setSelectedSymbol] = useState<DiagramComponent | null>(null)
	const dispatch = useAppDispatch()

	const symbolToOption = (symbol: DiagramComponent): Option => ({
		...symbol,
		id: symbol.id ?? '',
		label: symbol.name ?? '',
		value: symbol.id ?? '',
	})

	useEffect(() => {
		if (selectedShapes.length > 0) {
			const symbols = new Set<DiagramComponent>()
			selectedShapes.forEach(shape => {
				if (shape.entities) {
					const { Symbol } = shape.entities as DemoDiagramComponentMapping
					if (Symbol.length === 1) {
						symbols.add(Symbol[0])
					}
				}
			})

			if (symbols.size === 1) {
				const symbol = symbols.values().next().value as DiagramComponent
				setSelectedSymbol(symbol)
			}
		}
	}, [selectedShapes])

	const onSelect: SelectHandler<string, Option> = async (value, option) => {
		setSelectedSymbol(option)
		await Promise.all(
			selectedShapes.map(shape => {
				return shapeApi.linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(
					shape.id,
					value,
					DiagramComponentType.Symbol
				)
			})
		)
		if (applicationMode && image.id) {
			await dispatch(getAppData())
		}
	}

	if (!diagramComponents) {
		return
	}

	return (
		<Select
			style={{
				width: '320px',
			}}
			placeholder='Элемент разметки'
			options={diagramComponents.Symbol.map(symbolToOption) || []}
			value={selectedSymbol ? { value: selectedSymbol.id } : null}
			onSelect={onSelect}
			optionFilterProp='name'
			showSearch
		/>
	)
}

export default DEMOSelectors
