import type Konva from 'konva';
import { GUIDE_LINE_NAME } from './consts';

export const onDragEnd = (e: Konva.KonvaEventObject<DragEvent>): void => {
  const layer = e.target.getLayer();
  const lines = layer?.find(`.${GUIDE_LINE_NAME}`);
  const stage = e.target.getStage();

  if (stage) {
    stage.container().style.cursor = 'default';
  }

  if (lines) {
    for (const line of lines) {
      line.destroy();
    }
  }
};
