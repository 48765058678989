import React, { type FC } from 'react';
import SelectionTransformer from './SelectionTransformer';
import SelectionRectangle from './SelectionRectangle';

const SelectionTool: FC = () => {
  return (
    <>
      <SelectionTransformer />
      <SelectionRectangle />
    </>
  );
};

export default SelectionTool;
