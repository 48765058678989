import React, { type FC } from 'react';
import { Circle } from 'react-konva';
import type { ConnectionDto } from '../../../../generated/backend';

interface IConnector {
  connector: ConnectionDto;
}

const Connector: FC<IConnector> = ({ connector }) => {
  return (
    <Circle
      id={connector.id}
      x={connector.x}
      y={connector.y}
      width={connector.width}
      height={connector.height}
      fill={connector.color}
      shapeType="Connector"
      name="CONNECTOR"
      entity={{
        ...connector,
        shapeType: 'Connector',
      }}
    />
  );
};

export default Connector;
