import type { ApplicationMode } from '../../../../consts'
import type { DiagramComponent } from '../../../generated/backend'
import type { IShape } from '../../../store/slice/types'
import type {
	DemoDiagramComponentMapping,
	HazopDiagramComponentMapping,
	RbiDiagramComponentMapping,
} from '../../../store/actions/common/types'

export const getRelatedEntity = (
	applicationMode: ApplicationMode,
	shape: IShape<typeof applicationMode>
): DiagramComponent | null => {
	let entity: DiagramComponent | null = null

	if (applicationMode === 'DEMO_APP') {
		entity = (shape.entities as DemoDiagramComponentMapping).Symbol[0]
	}
	if (applicationMode === 'RBI_APP') {
		entity = (shape.entities as RbiDiagramComponentMapping).RbiCorrosionLoop[0]
	}
	if (applicationMode === 'HAZOP_APP') {
		entity = (shape.entities as HazopDiagramComponentMapping).HazopEquipment[0]
	}
	return entity
}
