import type { DiagramComponent } from '../../../generated/backend'

export type Option = DiagramComponent & {
	value: string
	label: string
}

export const diagramComponentToOption = (diagramComponent: DiagramComponent): Option => ({
	...diagramComponent,
	value: diagramComponent.id ?? '',
	label: diagramComponent.name ?? '',
})
