import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { type UserDtoRequest } from 'src/lib/src/generated/backend'
import { loginApi } from '../../api/api-wrapper'
import Logo from '../../assets/img/logo-black.svg'
import * as styles from './Login.module.css'

interface LocationState {
  from: string
}

const Login: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()

  const from = (location.state as LocationState)?.from || '/'

  const onSubmitData = async (values: UserDtoRequest): Promise<void> => {
    const { login, password } = values

    try {
      await loginApi.loginApiUsersLoginPost(login, password)
      await messageApi.success('Вы вошли в систему', 1.5, () => {
        navigate(from, { replace: true })
      })
    } catch (error) {
      await messageApi.error('Неверное имя пользователя или пароль')
    }
  }

  return (
    <div className={styles.container}>
      {contextHolder}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <Form form={form} onFinish={onSubmitData} className={styles.loginForm}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <Form.Item name='login' rules={[{ required: true, message: 'Введите имя пользователя' }]}>
          <Input prefix={<UserOutlined rev='' />} placeholder='Имя пользователя...' />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: 'Введите пароль' }]}>
          <Input.Password placeholder='Пароль...' prefix={<LockOutlined rev='' />} />
        </Form.Item>
        <Form.Item noStyle>
          <Button type='primary' htmlType='submit' className={styles.button}>
            Войти
          </Button>
        </Form.Item>

        <Button block type='link' href='/api/sso/login'>
          Войти через SSO
        </Button>
      </Form>
    </div>
  )
}

export default Login
