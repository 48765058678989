import React, { type FC, type ReactNode, useEffect } from 'react';
import Header from './header';
import Sider from './sider';
import Stage from './stage';
import type { ApplicationMode } from '../../types';
import { useParams } from 'react-router-dom';
import { getAppData, setApplicationMode, setImageId, setImageLoaded, useAppDispatch } from '../store';

interface IDrawing {
  applicationMode: ApplicationMode;
  loader?: ReactNode;
}

const Drawing: FC<IDrawing> = ({ applicationMode, loader }) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (id) {
        dispatch(setImageId(id));
      } else {
        dispatch(setImageLoaded({ loading: false, error: true }));
      }
    }, [id]);

    useEffect(() => {
      dispatch(setApplicationMode(applicationMode));
    }, [applicationMode]);

    useEffect(() => {
      void (async () => {
        if (id) {
          await dispatch(getAppData());
        }
      })();
    }, [id, applicationMode]);

    return (
      <>
        <Header uploadComponent={loader}/>
        <Sider/>
        <Stage/>
      </>
    );
  };

export default Drawing;
