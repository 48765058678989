import React, { type FC, useEffect, useState } from 'react'
import { Pagination as AntPagination } from 'antd'
import { useAppSelector } from '../../../store'
import { imageApi } from '../../../api/api-wrapper'
import { useNavigate } from 'react-router-dom'
import * as styles from '../style.module.css'

const Pagination: FC = () => {
	const [currentPage, setCurrentPage] = useState(0)
	const [pageCount, setPageCount] = useState(0)
	const [parentPDFId, setParentPDFId] = useState<string | null>(null)
	const { id } = useAppSelector(state => state.draw.image)
	const navigate = useNavigate()

	useEffect(() => {
		void (async () => {
			if (id) {
				const imageRes = await imageApi.getImageDrawingFileApiImageDrawingFileFileIdGet(id)
				setPageCount(imageRes.data.parent_pdf?.page_count ?? 0)
				setParentPDFId(imageRes.data.parent_pdf_id ?? null)
				setCurrentPage(imageRes.data.image_number ?? 0)
			}
		})()
	}, [id])

	const onChange = async (pageNumber: number): Promise<void> => {
		setCurrentPage(pageNumber)
		if (parentPDFId) {
			const res = await imageApi.getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(parentPDFId, pageNumber)
			navigate(`/drawing/${res.data.id}`)
		}
	}

	return (
		<AntPagination
			current={currentPage}
			total={pageCount}
			pageSize={1}
			onChange={onChange}
			className={styles.pagination}
			simple
		/>
	)
}

export default Pagination
