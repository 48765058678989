import {
  ConnectionApiFactory,
  ImageDrawingFileApiFactory,
  PdfDrawingFileApiFactory,
  ShapeApiFactory,
  ShapeTagApiFactory,
} from '../generated/backend'
import axios from 'axios'

export const shapeApi = ShapeApiFactory({
  isJsonMime(mime: string): boolean {
    return false
  },
  basePath: window.location.origin,
})

export const tagApi = ShapeTagApiFactory({
  isJsonMime(mime: string): boolean {
    return false
  },
  basePath: window.location.origin,
})

export const connectionApi = ConnectionApiFactory({
  isJsonMime(mime: string): boolean {
    return false
  },
  basePath: window.location.origin,
})

export const imageApi = ImageDrawingFileApiFactory({
  isJsonMime(mime: string): boolean {
    return false
  },
  basePath: window.location.origin,
})

export const pdfApi = PdfDrawingFileApiFactory({
  isJsonMime(mime: string): boolean {
    return false
  },
  basePath: window.location.origin,
})

axios.interceptors.request.use(
  config => {
    config.withCredentials = true
    config.headers['tenant-id'] = 'test' // todo env

    return config
  },
  async error => await Promise.reject(error)
)

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/login'
    }
    if (error.response && error.response.status === 403) {
      window.location.href = '/login'
    }

    return Promise.reject(error)
  }
)
