export const GUIDE_LINE_NAME = 'GUIDE_LINE';

export const LineGuideColor = 'rgba(0,161,255,0.5)';
export const LineGuideDash: [4, 6] = [4, 6];

export const LineGuideStrokeWidth = 1;

export enum Orientation {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export enum SnapPosition {
  START = 'START',
  CENTER = 'CENTER',
  END = 'END',
}
