import React, { type FC } from 'react';
import { useAppSelector } from '../../../store';
import Line from './Line';
import Rectangle from './Rectangle';
import Connector from './Connector';
import Tag from './Tag';

const Cleans: FC = () => {
  const { lines, rectangles, connectors, tags } = useAppSelector((state) => state.draw.stage.shapes);
  return (
    <>
      {lines.map((line) => {
        return <Line line={line} key={line.id} />
      })}
      {rectangles.map((rectangle) => {
        return <Rectangle rectangle={rectangle} key={rectangle.id} />
      })}
      {connectors.map((connector) => {
        return <Connector connector={connector} key={connector.id} />
      })}
      {tags.map((tag) => {
        return <Tag tagItem={tag} key={tag.id} />
      })}
    </>
  );
};

export default Cleans;
